
html {
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: auto;
}

body{
  overflow-x: hidden;
  
}

.lion {
  max-height: 100px;
  margin-top: 10px;
  text-shadow: black;
}

.fontColor {
  text-align: center;
  color: #FFF;
  font-size: 1.7rem;
  margin: 15px auto 30px auto;
}

.fontText{
  text-align: center;
  color: #FFF;
  font-size: 1.4rem;
  margin: 15px auto;
}

.projects {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 20%;

}
/* .projectsTitle{  
  text-align: center;
  color: #FFF;
  font-size: 3rem;
} */

.end {
  background-color: black;
  padding: 20px 0;
}



.statement {
  background: rgba(18, 24, 110, 0.281);
}

.education {
  background: rgba(13, 23, 156, 0.466);
}


.grids {
  max-width: 60%;
  margin: auto;
}

.intro {
  text-align: center;
}

.intro h1{
  margin-top: 0;
  padding-top: 25px;
  color: white;
  font-size: 2rem;
}


.line {
  width: 30%;
  border: 1.5px solid white;
}

.backgrounds {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  padding-top: 10%;
}

body{
  background-color: rgb(48, 48, 48);
}

/* .backgrounds .background {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  background-attachment: fixed;
  background-image: url('imgs/lake.jpg');
} */

.box {
  background-color: rgba(8, 0, 121, 0.082);
  margin-top: 50px;
  padding: 5%;
  margin-top: 50px;
  height: 70%;
  margin: auto;
}

.centered {
  text-align: center;
  height: '100%';
}

.imageCarousel1{
  height: 90%;
  width: 90%;
  max-width: 800px;
  max-height: 450px;
}

.imageCarousel2{
  height: 90%;
  width: 30%;
  max-width: 300px;
  max-height: 450px;
}

.video {
  height: 450px;
  width: 80%;
  max-width: 800px;
}

.gameImage {
  height: 300px;
  width: 600px;
}


@media only screen and (max-width: 700px) {
  .video {
    height: 350px;
  }

  html {
    font-size: 15px;
  }

  .grids{
    max-width: 70%;
  }

  .centered {
    height: 450px;
  }
}


@media only screen and (max-width: 500px) {
  .video {
    height: 250px;
  }
  
  html {
    font-size: 14px;
  }

  .grids{
    max-width: 80%;
  }

  .centered {
    height: 350px;
  }

  .gameImage {
    height: 200px;
    width: 400px;
  }

  .lion {
    max-height: 80px;
  }

  .backgrounds .background {
    background-color: black;
    background-image: none;
  }

  .statement {
    background: rgba(0, 9, 134, 0.466);
  }
}



@media only screen and (max-width: 350px) {
  .lion {
    max-height: 60px;
  }

  .video {
    height: 150px;
  }
  
  html {
    font-size: 10px;
  }

  .grids{
    max-width: 90%;
  }

  .centered {
    height: 200px;
  }
}


